import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/seo';
import get from 'lodash.get';
import InSalonClassRequest from 'components/InSalonClassRequest';

export default function EducatorApplicationPage(props) {
    let {
        data: { allContentfulEducatorApplication },
    } = props;
    let item = allContentfulEducatorApplication.nodes[0];
    return (
        <Layout>
            <SEO
                description={get(item, 'seoListing.description.description')}
                title={get(item, 'seoListing.seoTitle')}
                slug={'educator-application'}
            />
            <InSalonClassRequest page={item} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulEducatorApplication(
            filter: {
                id: { in: ["559449bc-3228-50a3-87bc-38ce5376a89f"] }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                id
                title
                subtitle {
                    subtitle
                }
                topTextItems {
                    items {
                        text
                        title
                    }
                    title
                }
                background {
                    localFile {
                        publicURL
                    }
                }
                node_locale
                seoListing {
                    ...ContentfulSeoListingFields
                }
            }
        }
    }
`;
