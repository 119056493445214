import React from 'react'
import get from 'lodash.get'
import { navigate } from 'gatsby-link'
import urls from 'helpers/url'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Select from 'react-select'
import {
    Spacing,
    Container,
    SharedStyles,
    Fieldset,
    FieldsGroup,
    FieldInput,
    FieldSubmit,
    FieldTextarea,
    EducatorApplication,
    FieldSelect,
} from '../kenra-storybook/index'
import settings from '../kenra-storybook/global/settings'
const { StSectionInfo, StTitle } = SharedStyles
import styled, { css } from 'styled-components'

const StFormColumns = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${settings.bp.small.view}) {
        flex-direction: row;
    }
`

const ClassTypesOptions = () => {
    return [
        { value: '', label: '' },
        { value: 'Color', label: 'Color' },
        { value: 'Haircutting', label: 'Hair Cutting' },
        { value: 'Styling', label: 'Styling' },
        { value: 'Business', label: 'Business' },
    ]
}

const DistributorTypesOptions = () => {
    return [
        { value: 'Cosmoprof', label: 'Cosmoprof' },
        { value: 'SalonCentric', label: 'SalonCentric' },
        { value: 'Armstrong McCall', label: 'Armstrong McCall' },
        { value: 'State RDA', label: 'State RDA' },
        { value: 'Paramount Beauty', label: 'Paramount Beauty' },
        { value: 'Other', label: 'Other' },
    ]
}

const CountryOptions = () => {
    return [
        { value: '', label: '' },
        { value: 'US', label: 'United States' },
        { value: 'CA', label: 'Canada' },
    ]
}

const YesNoOptions = () => {
    return [
        { value: '', label: '' },
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ]
}

function convertParametersToJsonArray(data) {
    let jsonArray = []
    Object.keys(data).map(key => {
        jsonArray.push({
            name: key,
            value: data[key],
        })
    })
    console.log(jsonArray)
    return jsonArray
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return [match[2], '-', match[3], '-', match[4]].join('')
    }
    return null
}

const InSalonClassRequestPage = ({ intl, page }) => {
    const [state, setState] = React.useState({})
    const toEmail = process.env.GATSBY_IN_SALON_REQUEST_EMAIL
    const handleChange = e => {
        if (e.target.name === 'Cell Phone') {
            setState({
                ...state,
                [e.target.name]:
                    formatPhoneNumber(e.target.value) || e.target.value,
            })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
    }

    const handleDistributorChange = e => {
        setState({
            ...state,
            distributors: e
                .map(i => {
                    return i.label
                })
                .join(', '),
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/.netlify/functions/sendTicketEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({
                toEmail: toEmail,
                subject: 'Kenra In Salon Class Request',
                body: convertParametersToJsonArray({ ...state }),
            }),
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 406) {
                        throw new Error(
                            intl.formatMessage({
                                id: 'form-submission-robot-error',
                            })
                        )
                    }
                    throw new Error(
                        intl.formatMessage({ id: 'form-submission-error' })
                    )
                }
            })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => {
                alert(error.message)
                document.getElementById('educatorapplicationform').reset()
            })
    }
    if (!page) {
        return null
    }
    let { title, subtitle, topTextItems, logo, background } = page

    return (
        <div style={{ backgroundColor: '#FFF' }}>
            <Spacing
                style={{
                    minHeight: '400px',
                    backgroundColor: '#0000001A',
                    backgroundImage: `url("${get(
                        background,
                        'localFile.publicURL'
                    )}")`,
                }}
            >
                <Container useLargeWidth>
                    <EducatorApplication items={topTextItems} />
                </Container>
            </Spacing>

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle colorBlack styleMedium>
                            <h2>{title}</h2>
                        </StTitle>
                        <p>{get(subtitle, 'subtitle')}</p>
                    </Container>
                </StSectionInfo>
            </Spacing>
            <Container useLargeWidth>
                <hr />
            </Container>

            <Spacing>
                <Container useLargeWidth>
                    <form
                        id="inSalonClassForm"
                        name="inSalonClassForm"
                        method="POST"
                        action={urls.salonRequestSubmitted}
                        onSubmit={handleSubmit}
                    >
                        <p hidden>
                            <label>
                                Don’t fill this out:
                                <input
                                    name="bot-field"
                                    onChange={handleChange}
                                />
                            </label>
                        </p>
                        <StFormColumns>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'in-salon-form-your-name',
                                })}
                            >
                                <FieldInput
                                    type="text"
                                    label="First and Last Name"
                                    id="pii-id-1"
                                    name="First & Last Name"
                                    onChange={handleChange}
                                    required
                                />
                            </Fieldset>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'in-salon-form-salon-name',
                                })}
                            >
                                <FieldInput
                                    type="text"
                                    id="salon-id-1"
                                    name="Salon Name"
                                    onChange={handleChange}
                                    required
                                />
                            </Fieldset>
                        </StFormColumns>

                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'in-salon-form-salon-address',
                            })}
                            divStyle={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-2"
                                name="Salon Address"
                                label="Street"
                                onChange={handleChange}
                                required
                                halfWidth
                            />
                            <FieldInput
                                type="text"
                                id="salon-id-3"
                                name="City"
                                label="City"
                                onChange={handleChange}
                                halfWidth
                            />
                            <FieldInput
                                type="text"
                                id="salon-id-4"
                                name="State / Province"
                                label="State / Province"
                                onChange={handleChange}
                                required
                                halfWidth
                            />
                            <FieldInput
                                type="text"
                                id="salon-id-6"
                                name="Postal / zip code"
                                label="Postal / zip code"
                                onChange={handleChange}
                                required
                                halfWidth
                            />
                            {/* <FieldInput
                                type="text"
                                id="salon-id-5"
                                name="Country"
                                label="Country"
                                onChange={handleChange}
                                required
                                halfWidth
                            /> */}

                            <FieldSelect
                                type="text"
                                id="salon-id-5"
                                name="Country"
                                label="Country"
                                onChange={handleChange}
                                options={CountryOptions()}
                                required
                            />

                        </Fieldset>
                        <StFormColumns>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'in-salon-form-phone',
                                })}
                            >
                                <FieldInput
                                    type="text"
                                    id="phone-id-1"
                                    name="phone"
                                    label="(###)###-####"
                                    onChange={handleChange}
                                />
                            </Fieldset>
                            <Fieldset
                                style={{ overflow: 'visible' }}
                                legend={intl.formatMessage({
                                    id: 'in-salon-form-distributor',
                                })}
                            >
                                <Select
                                    id="distributors"
                                    name="Distributor(s)"
                                    isMulti
                                    onChange={handleDistributorChange}
                                    options={DistributorTypesOptions()}
                                />
                            </Fieldset>
                        </StFormColumns>
                        <StFormColumns>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'in-salon-form-class-type',
                                })}
                            >
                                <FieldSelect
                                    type="text"
                                    id="class-type-id"
                                    name="CLASS TYPE"
                                    onChange={handleChange}
                                    options={ClassTypesOptions()}
                                    required
                                />
                            </Fieldset>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'in-salon-form-color-wetline',
                                })}
                            >
                                <FieldSelect
                                    type="text"
                                    name="ARE YOU A CURRENT KENRA COLOR OR WETLINE USER?"
                                    id="kenra-color-wetline-users-id-1"
                                    onChange={handleChange}
                                    options={YesNoOptions()}
                                />
                            </Fieldset>
                        </StFormColumns>
                        <FieldSubmit>
                            <input
                                value={intl.formatMessage({
                                    id: 'in-salon-form-submit-button',
                                })}
                                type="submit"
                            />
                        </FieldSubmit>
                    </form>
                </Container>
            </Spacing>
        </div>
    )
}

export default injectIntl(InSalonClassRequestPage)
